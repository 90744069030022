import Auth from "../components/auth/Auth";
import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    currentUser: {
      //lang : I18n.getCurrentLang()
    },
    token: null,
    token_type: null,
    lang : process.env.VUE_APP_DEFAULT_LANG
  },
  getters: {
    getCurrentLang: state => {
      return state.lang;
    }
  },
  mutations: {
    LOGOUT_USER(state) {
      state.currentUser = {};
      state.token = null;
      state.token_type = null;
     // state.lang = process.env.VUE_APP_DEFAULT_LANG;

      Cookies.remove('vuex', {
        path: '/',
        domain: process.env.VUE_APP_COOKIE_DOMAIN,
      })
    },
    SET_CURRENT_USER(state, user) {
      state.currentUser = user;
    },
    SET_TOKEN(state, tokenInformation) {
      state.token = tokenInformation.token;
      state.token_type = tokenInformation.token_type;
    },
    SET_CURRENT_LANG(state, lang) {
      state.lang = lang.code;
    },
  },
  actions: {
    async login({commit, dispatch}, creds) {
      try {
        let token = await Auth.login(creds);
        commit('SET_TOKEN', {
            token_type: 'Bearer',
            token: token.token
          }
        );
        let user = await Auth.getCurrentUser();
        commit('SET_CURRENT_USER', user);
        return user;
      } catch {
        return {error: "Username e/o password non corrette"}
      }
    },
    async loginAsGuest({commit, dispatch}) {
      try {
        let token = await Auth.loginAsGuest();
        commit('SET_TOKEN', {
            token_type: 'Bearer',
            token: token.token
          }
        );
        let user = await Auth.getCurrentUser();
        commit('SET_CURRENT_USER', user);
        return user;
      } catch {
        commit('LOGOUT_USER');
      }
    },
    async setToken({commit, dispatch}, token) {
      commit('SET_TOKEN', {
          token_type: 'Bearer',
          token: token.token
        }
      );

      let user = await Auth.getCurrentUser();
      commit('SET_CURRENT_USER', user);
    },
    async loggedIn({commit, dispatch}) {
      try {
        let user = await Auth.getCurrentUser();
        commit('SET_CURRENT_USER', user);
        if (user.logged) {
          return true;
        } else {
          return false;
        }

      } catch {
        commit('LOGOUT_USER');
        return false;
      }
    },
    async current({commit, dispatch}) {
      try {
        let user = await Auth.getCurrentUser();
        commit('SET_CURRENT_USER', user);
        return user;
      } catch {
        commit('LOGOUT_USER');
      }
    },
    changeCurrentLang({commit, dispatch}, lang) {
      commit('SET_CURRENT_LANG', lang);
    },
    async logout({commit, dispatch}) {
      try {
        await Auth.logout();
        commit('LOGOUT_USER');
        return document.location.href = '/';
      } catch {
        return {error: "impossibile effettuare il logout"}
      }
    },


  },

}
