import Vue from 'vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';

import store from '@/store';
import I18n from "../i18n/I18n";

Vue.use(VueAxios, Axios);

const BASE_URL = `${process.env.VUE_APP_API_BASE_URL}`;

function addAuthToken(data, headers) {
  const tokenType = localStorage.getItem('token_type');

  if (store.state.user.token) {
    Object.assign(
      headers,
      {
        Authorization: `${store.state.user.token_type} ${store.state.user.token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Accept-Language': I18n.getCurrentLangInIsoFormat()
      },
    );
  } else {
    Object.assign(
      headers,
      {
        Authorization: '',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Accept-Language': I18n.getCurrentLangInIsoFormat()
      });
  }
  return data;
}

function checkAuthToken(error) {
  if (error && error.response && error.response.status === 401 && error.response.config.url !== '/api/logout') {
    store.dispatch('user/logout');
    //window.location.href = '/';
    return Promise.reject(error);
  }

  if (error && error.response && error.response.status === 403) {
    //console.log(error.response);
    //if (error.response.data.detail === 'password expired') {
       //window.location.href = '/change-password';
    //}
    return Promise.reject(error);
  }

  const response = (error && error.response && error.response.data)
    ? error.response.data
    : error.response;

  return Promise.reject(response);
}

const CONFIG = {
  baseURL: BASE_URL,
  transformRequest: [
    addAuthToken,
  ],
};

const Api = Vue.axios.create(CONFIG);

Api.interceptors.response.use(response => response, checkAuthToken);

export default Api;
