import Vue from "vue";
import VuexPersist from 'vuex-persist';
import Vuex from "vuex";
import Cookies from 'js-cookie'
import messageModule from "./messages";
import userModule from "./user";
import snackbarModule from './snackbar';
import cartModule from './cart';
import alerts from "./alerts";

Vue.use(Vuex);


const vuexCookie = new VuexPersist({
  restoreState: (key, storage) => Cookies.getJSON(key),
  saveState: (key, state, storage) =>
    Cookies.set(key, state, {
      //;domain=.example.com;path=/"
      expires: 3,
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
      path : '/',
    }),
  //modules: ['user'], //only save user module
  //filter: (mutation) => mutation.type == 'logIn' || mutation.type == 'logOut'
})

const vuexPersist = new VuexPersist({
  key: `${process.env.VUE_APP_NAME}-${process.env.NODE_ENV}-storage`,
  storage: window.localStorage,
});

export default new Vuex.Store({
  modules: {
    messages: messageModule,
    user: userModule,
    snackbar: snackbarModule,
    cart: cartModule,
    alerts: alerts
  },
  plugins: [vuexCookie.plugin]// ,vuexPersist.plugin]
});
