export default {
  namespaced: true,
  state: {
    alerts: []
  },
  mutations: {
    SET_ALERT(state, alert) {
      state.alerts = state.alerts.concat(alert);
    },
    EMPTY_ALERT(state) {
      state.alerts = [];
    }
  },
  actions: {
    setAlert({commit}, alert) {
      alert.type = alert.type || "info";
      commit("SET_ALERT", alert);
    },
    emptyAlert({commit}) {
      commit("EMPTY_ALERT");
    },
    setAlertValidation({commit}, reason) {
      let alert = {
        type : 'danger'
      };
      if (reason.hasOwnProperty('violations') && reason.violations.length) {
        alert.text = this.$app.$t(reason.title) + "<br />";

        reason.violations.forEach(function(item) {
          alert.text+= this.$app.$t(item.message) + "<br />";
        }, this);

      } else {
        alert.type = 'danger';
        alert.text = this.$app.$t(reason.title) + ' : ' + this.$app.$t(reason.detail)
      }

      commit("EMPTY_ALERT");
      commit("SET_ALERT", alert);
    }
  },
  getters: {
    getAlerts: state => {
      return state.alerts;
    }
  },
  modules: {}
};
