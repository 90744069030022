import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import VueI18n from "vue-i18n";
import I18n from "../components/i18n/I18n";

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')
const EmptyContainer = () => import('@/containers/EmptyContainer');

// Views
const ProductsList = () => import('@/views/ProductsList');
const Categories = () => import('@/views/Categories');
const ProductDetail = () => import('@/views/ProductDetail');
const Register = () => import('@/views/account/Register');
const PreRegister = () => import('@/views/PreRegister');
const RegisterConfirm = () => import('@/views/RegisterConfirm');
const Login = () => import('@/views/Login');
const Checkout = () => import('@/views/Checkout');
const PaymentsSuccess = () => import('@/views/PaymentsSuccess');
const DemoSuccess = () => import('@/views/DemoSuccess');
const PaymentsCancel = () => import('@/views/PaymentsCancel');
const ResetPassword = () => import('@/views/ResetPassword');
const ResetPasswordConfirm = () => import('@/views/ResetPasswordConfirm');
const ChangePassword = () => import('@/views/account/ChangePassword');
const SendRegistrationToken = () => import('@/views/SendRegistrationToken');
const Profile = () => import('@/views/account/Profile');
const Feedback = () => import('@/views/account/Feedback');
const Support = () => import('@/views/account/Support');
const AnonymousSupport = () => import('@/views/pages/AnonymousSupport');
const AnonymousFeedback = () => import('@/views/pages/AnonymousFeedback');
const Policies = () => import('@/views/pages/Policies');
const Tos = () => import('@/views/pages/Tos');
const Tos2 = () => import('@/views/pages/Tos2');
const Sla = () => import('@/views/pages/Sla');
const Gdpr = () => import('@/views/pages/Gdpr');
const AboutUs = () => import('@/views/pages/AboutUs');
const TalkAboutUs = () => import('@/views/pages/TalkAboutUs');
const Faq = () => import('@/views/pages/Faq');
const VideoGuides = () => import('@/views/pages/VideoGuides');
const Page404 = () => import('@/views/pages/Page404');

Vue.use(Router);

let router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  //base: "/" + lang,
  scrollBehavior: () => ({y: 0}),
  routes: [

    {
      path: "/404",
      component: Page404,
    },
    {
      path: '/:lang',
      redirect: '/:lang/categories',
    },
    {
      path: '/:lang',
      //redirect: '/home',
      name: '',
      component: DefaultContainer,
      children: [
        {
          path: '/',
          redirect: '/:lang/categories',
          name: 'categories',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/:lang/pre-register',
              name: 'preRegister',
              component: PreRegister,
            },
            {
              path: '/:lang/account/register',
              name: 'register',
              component: Register,
            },
            {
              path: '/:lang/products/:slide',
              name: 'products',
              component: ProductsList,
              props: (route) => ({slide: route.params.slide}),
              async beforeEnter(to, from, next) {
                let loggedIn = await store.dispatch('user/loggedIn');
                if (!loggedIn) {
                  let user = await store.dispatch('user/loginAsGuest');
                  store.dispatch('cart/rememberCart');
                }

                next();
              }
            },
            {
              path: '/:lang/product/:productCode',
              name: 'productDetail',
              component: ProductDetail,
              props: (route) => ({productCode: route.params.productCode}),
              async beforeEnter(to, from, next) {
                let loggedIn = await store.dispatch('user/loggedIn');
                if (!loggedIn) {
                  let user = await store.dispatch('user/loginAsGuest');
                  store.dispatch('cart/rememberCart');
                }

                next();
              }
            },
            {
              path: '/:lang/register-confirm',
              name: 'registerConfirm',
              component: RegisterConfirm,
            },
            {
              path: '/:lang/checkout',
              name: 'checkout',
              component: Checkout,
            },
            {
              path: '/:lang/payment-success',
              name: 'paymentSuccess',
              component: PaymentsSuccess,
              props: (route) => ({query: route.query})
            },
            {
              path: '/:lang/payment-cancel',
              name: 'paymentCancel',
              component: PaymentsCancel,
              props: (route) => ({query: route.query})
            },
            {
              path: '/:lang/demo-success',
              name: 'demoSuccess',
              component: DemoSuccess,
              props: (route) => ({query: route.query})
            },
            {
              path: '/:lang/account/change-password',
              name: 'changePassword',
              component: ChangePassword,
            },
            {
              path: '/:lang/login',
              name: 'login',
              component: Login,
            },
            {
              path: '/:lang/reset-password',
              name: 'resetPassword',
              component: ResetPassword,
            },
            {
              path: '/:lang/reset-password-confirm',
              name: 'resetPasswordConfirm',
              component: ResetPasswordConfirm,
              props: (route) => ({query: route.query})
            },
            {
              path: '/:lang/send-registration-token',
              name: 'sendRegistrationToken',
              component: SendRegistrationToken,
            },
            {
              path: '/:lang/account/profile',
              name: 'profile',
              component: Profile,
            },
            {
              path: '/:lang/account/feedback',
              name: 'feedback',
              component: Feedback,
            },
            {
              path: '/:lang/pages/feedback',
              name: 'anonymousFeedback',
              component: AnonymousFeedback,
            },
            {
              path: '/:lang/account/support',
              name: 'support',
              component: Support,
            },
            {
              path: '/:lang/pages/support',
              name: 'anonymousSupport',
              component: AnonymousSupport,
            },
            {
              path: '/:lang/pages/about-us',
              name: 'aboutUs',
              component: AboutUs,
            },
            {
              path: '/:lang/pages/talk-about-us',
              name: 'talkAboutUs',
              component: TalkAboutUs,
            },
            {
              path: '/:lang/pages/policies',
              name: 'policies',
              component: Policies,
            },
            {
              path: '/:lang/pages/tos',
              name: 'tos',
              component: Tos,
            },
            {
              path: '/:lang/pages/tos2',
              name: 'tos2',
              component: Tos2,
            },
            {
              path: '/:lang/pages/sla',
              name: 'sla',
              component: Sla,
            },
            {
              path: '/:lang/pages/gdpr',
              name: 'gdpr',
              component: Gdpr,
            },
            {
              path: '/:lang/pages/faq',
              name: 'faq',
              component: Faq,
            },
            {
              path: '/:lang/pages/video-guides',
              name: 'videoGuides',
              component: VideoGuides,
            },
          ]
        },
        {
          path: '/:lang/categories',
          name: 'categories',
          component: Categories,
        },
        {
          path: "*",
          redirect: '/404',
          component: Page404,
        }

      ],
    }
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name === 'checkout') {
    /* se l'utente è diverso da customer */
    if (store.state.user.token && store.state.user.currentUser.customer !== 1) {
      next({
        name: 'login'
      });
      return;
    }
  }
  next();
});

export default router;
