<template>
  <div>
    <router-view></router-view>
    {{showMessages}}
  </div>

</template>

<script>

  import {mapState, mapGetters} from "vuex";

  import Vue from 'vue';
  import VueNotifications from 'vue-notifications'
  import miniToastr from 'mini-toastr'// https://github.com/se-panfilov/mini-toastr

  const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn',

  }

  miniToastr.init({
    types: toastTypes
  });

  function toast({title, message, type, timeout, cb}) {
    return miniToastr[type](message, title, timeout, cb)
  }

  const options = {
    success: toast,
    error: toast,
    info: toast,
    warn: toast
  }

  Vue.use(VueNotifications, options);

  export default {
    name: 'app',
    data() {
      return {
        locale: this.$i18n.locale,
        snackbarMessage: {}
      }
    },
    watch: {
      currentLang: {
        handler() {
          this.$i18n.locale = this.currentLang;
        },
        deep: true
      },
    },
    computed: {
      ...mapState({
        message: state => state.messages.message,
        currentUser: state => state.user.currentUser,
        currentLang: state => state.user.lang,
        snackbars: state => state.snackbar.snackbars,
      }),
      showMessages() {
        if (this.snackbars) {
          this.snackbars.forEach(snackbar => {

            this.showSnackbar({
              type: VueNotifications.types[snackbar.type],
              title: snackbar.title,
              message: snackbar.message,
              timeout: 5000,
              cb: () => {
                this.$store.dispatch('snackbar/emptySnackbar');
              }
            })

          });
        }
      },

    },
    notifications: {
      showSnackbar: {
        type: VueNotifications.types.info,
        title: '',
        message: ""
      }

    },
  };
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/coreui-icons.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
