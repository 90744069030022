export default {
  namespaced: true,
  state: {
    snackbars: []
  },
  mutations: {
    SET_SNACKBAR(state, snackbar) {
      state.snackbars = state.snackbars.concat(snackbar);
    },
    EMPTY_SNACKBAR(state) {
      state.snackbars = [];
    },
  },
  actions: {
    setSnackbar({commit}, snackbar) {
      snackbar.type = snackbar.type || 'info';
      commit('SET_SNACKBAR', snackbar);
    },
    emptySnackbar({commit}) {
      commit('EMPTY_SNACKBAR');
    },
  },
  getters: {},
  modules: {}
}
