import I18n from "../i18n/I18n";
export default {
  get(apiUrl, params) {
    // TODO move it in env dist!
    const apis = {
      LOGIN: '/login_check',
      LOGIN_FROM_CART: '/api/login-from-cart',
      LOGIN_AS_GUEST: '/m/create-anonymous-contact',
      LOGOUT: '/api/logout',
      PRODUCTS: '/m/olap-datasheet-cleaned2?Language=' + I18n.getCurrentLangInIsoFormat(),
      PRODUCTS_CATEGORIES: '/m/olap-datasheet-categories?Language=' + I18n.getCurrentLangInIsoFormat(),
      PRODUCT: '/m/olap-datasheet-cleaned2?Language='+ I18n.getCurrentLangInIsoFormat() +'&Item_Code=:Code',
      OPERATING_SYSTEMS: '/m/operating-systems?Item=:Code&Related_Item_Category=LIC&Related_Item_Group=OS',
      IPV4: '/m/ipv4?Item=:Code&Related_Item_Category=MP&Related_Item_Group=IPV4',
      MAX_IPV4: '/m/ipv4-max?Item_Code=:Code&Language=' + I18n.getCurrentLangInIsoFormat(),
      IPV6: '/m/ipv6?Item=:Code&Related_Item_Category=MP&Related_Item_Group=IPV6',
      CONTROL_PANELS: '/m/control-panels?Item=:Code&Related_Item_Category=LIC&Related_Item_Group=CP',
      CART: '/api/cart',
      CART_ADD: '/api/cart',
      CART_ADD_DISCOUNT: '/api/cart-discount',
      CART_CREATE: '/api/create-cart',
      CART_GET_OR_CREATE: '/api/get-or-create-cart',
      WHO_I_AM: '/api/user/me',
      CHANGE_PASSWORD: '/api/change-password',
      RESET_PASSWORD: '/reset-password',
      RESET_PASSWORD_CONFIRM: '/reset-password-confirm',
      COUNTRIES_LIST : '/m/countries?Language=' + I18n.getCurrentLangInIsoFormat(),
      COUNTIES_LIST : '/m/counties',
      PRE_REGISTER : '/api/registration',
      REGISTER : '/api/registration-complete',
      REGISTER_CONFIRM : '/api/registration-confirm',
      DISCOUNTS : '/m/discounts',
      SUBSCRIPTION : '/payment/subscription',
      EXPRESS_PAYMENT: '/payment/express',
      TRIAL_CHECKOUT : '/payment/trial',
      PAYMENT_SUCCESS : '/payment/success',
      PAYMENT_CANCEL : '/payment/cancel',
      SEND_REGISTRATION_TOKEN : '/send-registration-token',
      PROFILE : '/api/profile',
      PROFILE_EDIT : '/api/profile-edit',
      CART_DELETE : '/api/cart-delete',
      PUBLIC_FEEDBACK: '/feedback',
      PUBLIC_SUPPORT: '/os-ticket',
      FEEDBACK: '/api/feedback',
      AVATAR: '/api/avatar',
      SUPPORT: '/api/os-ticket',
      PAYMENTS : '/payment/purchases',
      CART_PROPERTIES: '/api/cart-properties'
    };

    if (params) {
      let apiUrlStr = apis[apiUrl];
      for (const param in params) {
        if (param) {
          const searchParam = `:${param}`;
          apiUrlStr = apiUrlStr.replace(searchParam, params[param]);
        }
      }
      return apiUrlStr;
    }
    return apis[apiUrl];
  },
};
