import Api from '../common/Api';
import ApiResolver from '../common/ApiResolver';

const ROLE_ANONYMOUS = 'ROLE_ANONYMOUS';
const ROLE_CUSTOMER = 'ROLE_CUSTOMER';
const ROLE_REGISTERED = 'ROLE_REGISTERED';
const ROLE_PENDING = 'ROLE_PENDING';

export default {
  /**
   * @return {string}
   */
  ROLE_ANONYMOUS() {
    return ROLE_ANONYMOUS;
  },
  /**
   * @return {string}
   */
  ROLE_CUSTOMER() {
    return ROLE_CUSTOMER;
  },
  /**
   * @return {string}
   */
  ROLE_REGISTERED() {
    return ROLE_REGISTERED;
  },

  /**
   * @returns {string}
   */
  ROLE_PENDING() {
    return ROLE_PENDING;
  },

  whoIam() {
    return new Promise((resolve, reject) => {
      Api.get(ApiResolver.get('WHO_I_AM'))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loginAsGuest() {
    return new Promise((resolve, reject) => {
      Api.post(ApiResolver.get('LOGIN_AS_GUEST'), JSON.stringify({}))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loginFromCart(creds) {
    let loginUrl = ApiResolver.get('LOGIN_FROM_CART');
    return new Promise((resolve, reject) => {
      Api.post(
        loginUrl,
        JSON.stringify(creds))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  login(creds) {
    let loginUrl = ApiResolver.get('LOGIN');
    return new Promise((resolve, reject) => {
      Api.post(
        loginUrl,
        JSON.stringify(creds))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout() {
    let logoutUrl = ApiResolver.get('LOGOUT');

    return new Promise((resolve, reject) => {
      Api.post(
        logoutUrl,
        JSON.stringify([]))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  isLogged() {
    return !!localStorage.getItem('token');
  },
  isRole(role) {
    return localStorage.getItem(role) === "1" || localStorage.getItem(role) === 1;
  },
  getCurrentUser() {
    return new Promise((resolve, reject) => {
      Api.get(ApiResolver.get('WHO_I_AM'))
        .then((response) => {

          if (response.data.cart) {
            localStorage.setItem('currentCart', response.data.cart);
          }
          let currentUser = {
            ...response.data,
            ...{
              anonymous: (response.data.roles.indexOf('ROLE_ANONYMOUS') >= 0 ? 1 : 0),
              customer: (response.data.roles.indexOf('ROLE_CUSTOMER') >= 0 ? 1 : 0),
              registered: (response.data.roles.indexOf('ROLE_REGISTERED') >= 0 ? 1 : 0),
              pending: (response.data.roles.indexOf('ROLE_PENDING') >= 0 ? 1 : 0),
              logged: true
            }
          };

          resolve(currentUser);
        })
        .catch((error) => {
          resolve({
            username: null,
            displayName: null,
            anonymous: true,
            customer: false,
            registered: false,
            logged: false
          });
        })
    });
  }
};
