export default {
    namespaced: true,
    state: {
        message: '',
    },
    getters: {
        getMessage: state => {
            return state.message;
        }
    },
    mutations: {
        SET_MESSAGE(state, message) {
            //console.log('messaggio :' + message)
            state.message = message;

            //console.log('stato: ' + state.message);
        },
    },
    actions: {
        setMessage({commit},message) {
            commit('SET_MESSAGE', message);
        }
    }
}
