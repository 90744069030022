import store from '@/store';

export default {

  getLangs() {
    return [
      {
        'code': 'it',
        'isoCode': 'it-IT',
        'navCode': 'ITA',
        'icon': 'flag-icon flag-icon-it',
        'label': 'Italiano',
        'short': 'IT',
      },
      {
        'code': 'en',
        'isoCode': 'en-US',
        'navCode': 'ENU',
        'icon': 'flag-icon flag-icon-gb',
        'label': 'English',
        'short': 'EN',
      },
      {
        'code': 'de',
        'isoCode': 'de-DE',
        'navCode': 'DEU',
        'icon': 'flag-icon flag-icon-de',
        'label': 'Deutsch',
        'short': 'DE',
      },
      {
        'code': 'fr',
        'isoCode': 'fr-FR',
        'navCode': 'FRA',
        'icon': 'flag-icon flag-icon-fr',
        'label': 'Français',
        'short': 'FR',
      },
      {
        'code': 'es',
        'isoCode': 'es-ES',
        'navCode': 'ESP',
        'icon': 'flag-icon flag-icon-es',
        'label': 'Español',
        'short': 'ES',
      },
      /*
      {
        'code': 'ru',
        'isoCode': 'ru-RU',
        'navCode': 'RUS',
        'icon': 'flag-icon flag-icon-ru',
        'label': 'Pусский',
        'short': 'RU',
      }
      */

    ]
  },
  getCurrentLangDetail() {
    const urlParts = window.location.pathname.split('/');

    if (urlParts[1] !== this.getCurrentLang()) {

      const existLang = this.getLangs().filter((lang) => (lang.code === urlParts[1] || lang.isoCode === urlParts[1]))[0];

      if (existLang) {
        this.setCurrentLang(urlParts[1], true);
      } else {
        this.setCurrentLang(this.getCurrentLang(), true);
      }
    }
    return this.getLangs().filter((lang) => (lang.code === this.getCurrentLang() || lang.isoCode === this.getCurrentLang()))[0]
  },
  getCurrentLang() {
    const getCurrentLang = store.state.user.lang;

    const currentLangSelected = this.getLangs().filter((lang) => (lang.code === getCurrentLang || lang.isoCode === getCurrentLang))[0]

    return (typeof currentLangSelected !== 'undefined' && currentLangSelected !== null ? currentLangSelected.code : process.env.VUE_APP_DEFAULT_LANG);
  },
  getCurrentLangInIsoFormat() {
    const getCurrentLang = store.state.user.lang;

    const currentLangSelected = this.getLangs().filter((lang) => (lang.code === getCurrentLang || lang.isoCode === getCurrentLang))[0]

    return (typeof currentLangSelected !== 'undefined' && currentLangSelected !== null ? currentLangSelected.isoCode : process.env.VUE_APP_DEFAULT_LANG_ISO);
  },
  setCurrentLang(langSelected, redirect) {
    const newLangSelected = this.getLangs().filter((lang) => (lang.code === langSelected || lang.isoCode === langSelected))[0]

    store.dispatch('user/changeCurrentLang', newLangSelected);

    if (redirect) {

      const urlParts = window.location.pathname.split('/');

      urlParts[1] = newLangSelected.code;

      const redirectUrl = urlParts.join("/");

      return window.location.href = redirectUrl;
    }
  }
}
