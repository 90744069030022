// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill';
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import App from './App';
import router from './router/index';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuelidate from 'vuelidate';
import VueI18n from 'vue-i18n'
import Vue2Filters from 'vue2-filters'
import VueMoment from 'vue-moment' ;
import it from '../i18n/messages/it-IT.json';
import en from '../i18n/messages/en-GB.json';
import de from '../i18n/messages/de-DE.json';
import es from '../i18n/messages/es-ES.json';
import fr from '../i18n/messages/fr-FR.json';
import ru from '../i18n/messages/ru-RU.json';
import I18n from "./components/i18n/I18n";
import store from "./store";

// todo
// cssVars()

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueI18n);
Vue.use(VueAxios, axios);
Vue.use(Vue2Filters);
Vue.use(VueMoment);

const messages = {
  it: it,
  en: en,
  es: es,
  de: de,
  fr: fr,
  /* ru: ru */
};
// Create VueI18n instance with options

const i18n = new VueI18n({
  locale: I18n.getCurrentLangDetail().code, // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
})

/* eslint-disable no-new */
let app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  template: '<App/>',
  components: {
    App,
  },
});
store.$app = app;
