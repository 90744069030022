import Cart from "../components/cart/Cart";

export default {
  namespaced: true,
  state: {
    trial: false,
    preserve: true,
    number: null,
    amount: null,
    totals: [],
    rows: [],
    individualPerson: null,
    isLoadingCart: false,
  },
  getters: {
    getCart: state => {
      return state;
    },
    getCartIsLoading: state => {
      return state.isLoadingCart;
    },
    getCartNumber: state => {
      return state.number;
    },
    getPreserveCartNumber: state => {
      return state.preserve ? state.number : null;
    },
  },
  mutations: {
    REMEMBER_CART(state) {
      state.preserve = true;
    },
    IS_LOADING_CART(state, loading) {
      state.isLoading = loading;
    },
    FORGOT_CART(state) {
      state.preserve = false;
    },
    EMPTY_CART(state) {
      state.trial = false;
      state.preserve = true;
      state.number = null;
      state.amount = null;
      state.totals = [];
      state.rows = [];
      state.individualPerson = null;
      state.isLoadingCart = false;

    },
    SET_CART(state, cart) {

      function getType(no) {
        if (no.startsWith("010.")) return 'ipv4';
        if (no.startsWith("15")) return 'os';
        if (no.startsWith("17")) return 'cp';
        if (no.startsWith("VPS")) return 'vps';
      }

      state.number = cart.No;
      /* modifica voluta da Paolo e Gianni per visualizzare SEMPRE i prezzi + iva */
      state.amount = cart.Amount;
      switch (true) {
        case cart.Individual_Person === false && cart.Sell_to_Customer_Name === "Gruppo Clienti Anonimi" :
          state.individualPerson = "1";
          //state.amount = cart.Amount_Including_VAT;
          break;
        case cart.Individual_Person === false :
          state.individualPerson = "0";
          //state.amount = cart.Amount;
          break;
        case cart.Individual_Person === true :
          state.individualPerson = "1";
          //state.amount = cart.Amount_Including_VAT;
          break;
      }
      state.amountIncludingVat = cart.Amount_Including_VAT;
      state.totals = cart.amountInformation;
      if (cart.hasOwnProperty('SalesLines') && cart.SalesLines.hasOwnProperty('M_SalesLines')) {
        state.rows = cart.SalesLines.M_SalesLines.map((item) => {
          if ('vps' === getType(item.No)) {
            Cart.checkTrial(item.No).then((response) => (state.trial = response));
          }
          return {
            type: getType(item.No),
            quantity: item.Quantity,
            description: item.Description,
            code: item.No,
          }
        });
      }
    },
  },
  actions: {
    async getOrCreate({commit, dispatch}, params) {
      commit('IS_LOADING_CART', true);
      commit('SET_CART', await Cart.getOrCreate());
      commit('IS_LOADING_CART', false);
    },
    async get({commit, dispatch}, params) {
      commit('IS_LOADING_CART', true);
      let cart = {};
      try {
        cart = await Cart.get();
      } catch (e) {
      }
      commit('EMPTY_CART', {});
      commit('SET_CART', cart);
      commit('IS_LOADING_CART', false);
    },
    rememberCart({commit, dispatch}, params) {
      commit('REMEMBER_CART', {});
    },
    forgotCart({commit, dispatch}, params) {
      commit('FORGOT_CART', {});
      commit('EMPTY_CART', {});
    },
  }
}
