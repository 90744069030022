import Api from "../common/Api";
import ApiResolver from "../common/ApiResolver";

export default {
  get() {
    return new Promise((resolve, reject) => {
      Api.get(ApiResolver.get('CART'))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create() {
    return new Promise((resolve, reject) => {
      Api.post(ApiResolver.get('CART_CREATE'), JSON.stringify({}))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOrCreate(vps, discount) {
    return new Promise((resolve, reject) => {
      Api.post(ApiResolver.get('CART_GET_OR_CREATE'), JSON.stringify({vps: vps, discount: discount}))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  put(row) {
    return new Promise((resolve, reject) => {
      Api.put(ApiResolver.get('CART_ADD'), JSON.stringify(row))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addDiscount(discount) {
    return new Promise((resolve, reject) => {
      Api.post(ApiResolver.get('CART_ADD_DISCOUNT'), JSON.stringify(discount))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkTrial(vps) {
    return new Promise((resolve, reject) => {
      Api.get(ApiResolver.get('PRODUCTS') + '&Item_Code=' + vps + '&Label_Code=TRIAL&Boolean=1')
        .then((response) => {
          if (response.data.hasOwnProperty(vps)) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(function (error) {
          reject(false);
        });
    });
  }
};
